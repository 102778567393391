import io from 'socket.io-client';
import { debugAPI } from '~/modules/SDK/debug/debugAPI';
import { socketOnSleepRecoverReconnect } from '~/modules/SDK/Socket3/socketOnSleepRecoverReconnect';
import { useMeStore } from '~/modules/SDK/me/useMeStore';
import { isError, isUndefined } from 'lodash';
import { fr_datafeedStatus } from '~/pages/heineken_template/_fr/fr_datafeedStatus';
import { proxy, ref } from 'valtio';
import { createChartDatafeedOfPolling } from '~/modules/SDK/chart4/createChartDatafeedOfPolling';
import { fr_agents } from '~/pages/heineken_template/_fr/fr_agents';
import { TvAPIs } from '~/configs/apirc/TvAPIs';
import { fr_me } from '~/pages/heineken_template/_fr/fr_me';
var SocketAction;
(function (SocketAction) {
    SocketAction["subscribeSymbol"] = "subscribe-symbol";
    SocketAction["unsubscribeSymbol"] = "unsubscribe-symbol";
})(SocketAction || (SocketAction = {}));
export class ChartingDatafeedModule {
    state;
    ioAsRef;
    constructor(initialState) {
        debugAPI.datafeed2.log(`new constructor(...)`, { initialState });
        this.state = proxy(initialState);
        this.ioAsRef = {
            current: null,
        };
        this.tvAPIs = new TvAPIs(this.state.server.historyUrl);
        if (this.state.server.wsUrl) {
            this.io = ref(io(this.state.server.wsUrl || '', {
                query: {
                    uid: fr_me.me.uid || useMeStore.getState().meUserState?.uid,
                    agent: fr_agents.agent,
                    product: fr_agents.product,
                },
                autoConnect: false,
                reconnection: true, // 休眠/斷網後，前端應能自動重連。 TODO: 檢查 enable 後是否有其他可能的 bug
            }));
            this.io.emit = ref(new Proxy(this.io.emit, {
                apply: (target, ev, args) => {
                    debugAPI.datafeed2.log(`socket.emit(${args[0]})`, args[1], {
                        subTopics: this.subTopics,
                    });
                    Reflect.apply(target, ev, args);
                },
            }));
            this.ioAsRef.current = ref(this.io);
        }
        debugAPI.datafeed2.log(`new constructor(...) ; End`, { initialState, this: this });
    }
    create() {
        if (!this.state.server.wsUrl) {
            debugAPI.datafeed2.log(`.create() ;PollingServer`, { this: this });
            return createChartDatafeedOfPolling(fr_agents.agent, this.state.server.historyUrl, 5000);
        }
        if (this.state.server.wsUrl) {
            this.registerSocketListeners();
        }
        debugAPI.datafeed2.log(`.create() ;SocketServer`, { this: this });
        return {
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     onReady: callback => {
             *       type Data = {
             *         supported_resolutions: string[]
             *         supports_group_request: boolean
             *         supports_marks: boolean
             *         supports_search: boolean
             *         supports_time: boolean
             *         supports_timescale_marks: boolean
             *         symbols_types: { name: string; value: string }[]
             *         timezone: string
             *       }
             *
             *       $http.get('/config', {}).then((response: { data: Data }) => {
             *         log(`chart.onReady()`, response.data)
             *         return callback({
             *           ...response.data,
             *         } as AnyFIXME)
             *       })
             *     }
             *   }
             */
            onReady: callback => {
                debugAPI.datafeed2.log(`.onReady()`, { this: this });
                this.tvAPIs.fetchConfig().then(config => {
                    callback(config);
                });
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
             *       const params: Partial<{
             *         limit: LiteralUnion<30, number>
             *         query: string
             *         exchange: string
             *         type: LiteralUnion<'os_futures', string>
             *       }> = {
             *         limit: 30,
             *         query: userInput,
             *         exchange,
             *         type: symbolType,
             *       }
             *
             *       $http.get('/search', params).then(
             *         (response: {
             *           data: {
             *             description: string
             *             exchange: string
             *             full_name: string
             *             symbol: string
             *             type: string
             *           }[]
             *         }) => {
             *           onResultReadyCallback(response.data as AnyFIXME)
             *         },
             *       )
             *     }
             *   }
             */
            searchSymbols: (userInput, exchange, symbolType, onResultReadyCallback) => {
                this.tvAPIs
                    .search({
                    limit: 30,
                    query: userInput,
                    exchange,
                    type: symbolType,
                })
                    .then(info => {
                    onResultReadyCallback(info);
                });
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
             *       $http
             *         .get('/symbols', { symbol: symbolName })
             *         .then(resp => {
             *           const data: ParametersHead<typeof onSymbolResolvedCallback> = {
             *             ...resp.data,
             *           }
             *
             *           onSymbolResolvedCallback(data)
             *         })
             *         .catch(resp => {
             *           onResolveErrorCallback(resp)
             *         })
             *     }
             *   }
             */
            resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
                this.tvAPIs
                    .resolveSymbol({ symbol: symbolName })
                    .then(data => {
                    onSymbolResolvedCallback(data);
                })
                    .catch(res => {
                    onResolveErrorCallback(res.data);
                });
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     getBars: (symbolInfo, resolution, periodParams, onResult, onError) => {
             *       const isFirstCall = periodParams.firstDataRequest
             *       const params = {
             *         symbol: symbolInfo.ticker,
             *         resolution,
             *         from: periodParams.from,
             *         to: periodParams.to,
             *         ws: true,
             *         metaData: true,
             *       }
             *       $http
             *         .get('/history', params)
             *         .then(async resp => {
             *           const metaData: HistoryMetadata = {
             *             noData: resp.data.metaData.noData,
             *             nextTime: resp.data.metaData.nextTime,
             *           }
             *           let historyKbars = resp.data.bars
             *
             *           // 第一次呼叫若noData, retry一次
             *           if (isFirstCall && metaData.noData) {
             *             const retryResp: AnyFIXME = await $http.get('/history', params)
             *             metaData.noData = retryResp.data.metaData.noData
             *             metaData.nextTime = retryResp.data.metaData.nextTime
             *             historyKbars = retryResp.data.bars
             *           }
             *
             *           // 記錄history回傳的最後一根K棒時間, 作為後續ws推播K棒參考
             *           if (isFirstCall && historyKbars.length > 0) {
             *             const subscriberUID = `${params.symbol}_#_${params.resolution}`
             *             historyLastKbarTime[subscriberUID] =
             *               historyKbars[historyKbars.length - 1].time
             *           }
             *
             *           onResult(historyKbars, metaData)
             *           return true
             *         })
             *         .catch((error: DatafeedHttpError) => {
             *           let reason = error.message
             *           if (error.response) {
             *             reason = error.response.data ? error.response.data.error.errCode : reason
             *           }
             *           onError(reason)
             *           return false
             *         })
             *     }
             *   }
             */
            getBars: (symbolInfo, resolution, periodParams, onResult, onError) => {
                const isFirstCall = periodParams.firstDataRequest;
                const params = {
                    symbol: symbolInfo.ticker,
                    resolution,
                    from: periodParams.from,
                    to: periodParams.to,
                    ws: true,
                    metaData: true,
                };
                this.tvAPIs
                    .fetchHistory(params)
                    .then(async (data) => {
                    const metaData = {
                        noData: data.metaData.noData,
                        nextTime: data.metaData.nextTime,
                    };
                    let historyKbars = data.bars;
                    // 第一次呼叫若noData, retry一次
                    if (isFirstCall && metaData.noData) {
                        const retryResp = await this.tvAPIs.fetchHistory(params);
                        metaData.noData = retryResp.metaData.noData;
                        metaData.nextTime = retryResp.metaData.nextTime;
                        historyKbars = retryResp.bars;
                    }
                    // 記錄history回傳的最後一根K棒時間, 作為後續ws推播K棒參考
                    if (isFirstCall && historyKbars.length > 0) {
                        const subscriberUID = `${params.symbol}_#_${params.resolution}`;
                        this.historyLastKbarTime[subscriberUID] = historyKbars[historyKbars.length - 1].time;
                    }
                    onResult(historyKbars, metaData);
                    return true;
                })
                    .catch((res) => {
                    const errMsg = isError(res) ? res.message : res.data.error.errCode;
                    onError(errMsg);
                    console.error(`datafeed.getBars()`, errMsg);
                    return false;
                });
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     subscribeBars: (
             *       symbolInfo,
             *       resolution,
             *       onRealtimeCallback,
             *       subscriberUID,
             *       onResetCacheNeededCallback,
             *     ) => {
             *       // 使休眠斷線後的 Chart 重新取得資料用（不確定有無真實效果）
             *       // https://github.com/cory8249/charting_library/wiki/Chart-Methods#resetdata
             *       // onResetCacheNeededCallback()
             *       log(`chart.subscribeBars()`, { subTopics })
             *       const sub = subTopics[subscriberUID]
             *       if (sub) return
             *
             *       const symbol = symbolInfo.ticker
             *       socket.emit(SocketAction.subscribeSymbol, { symbol, resolution, subscriberUID })
             *
             *       if (!symbol) {
             *         console.warn(`找不到 symbol`, { symbolInfo })
             *       }
             *
             *       subTopics[subscriberUID] = {
             *         symbol,
             *         subscriberUID,
             *         resolution,
             *         symbolInfo,
             *         lastBarTime: undefined,
             *         listener: onRealtimeCallback,
             *       }
             *     }
             *   }
             */
            subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
                // 使休眠斷線後的 Chart 重新取得資料用（不確定有無真實效果）
                // https://github.com/cory8249/charting_library/wiki/Chart-Methods#resetdata
                // onResetCacheNeededCallback()
                debugAPI.datafeed2.log(`chart.subscribeBars()`, { subTopics: this.subTopics });
                const sub = this.subTopics[subscriberUID];
                if (sub)
                    return;
                if (!this.io)
                    return;
                const symbol = symbolInfo.ticker;
                this.io.emit(SocketAction.subscribeSymbol, { symbol, resolution, subscriberUID });
                if (!symbol) {
                    console.warn(`找不到 symbol`, { symbolInfo });
                }
                this.subTopics[subscriberUID] = {
                    symbol,
                    subscriberUID,
                    resolution,
                    symbolInfo,
                    lastBarTime: undefined,
                    listener: onRealtimeCallback,
                };
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     unsubscribeBars: subscriberUID => {
             *       const sub = subTopics[subscriberUID]
             *       if (!sub) return
             *       if (historyLastKbarTime[subscriberUID]) delete historyLastKbarTime[subscriberUID]
             *       socket.emit(SocketAction.unsubscribeSymbol, { subscriberUID })
             *       delete subTopics[subscriberUID]
             *     }
             *   }
             */
            unsubscribeBars: subscriberUID => {
                const sub = this.subTopics[subscriberUID];
                if (!sub)
                    return;
                if (!this.io)
                    return;
                if (this.historyLastKbarTime[subscriberUID])
                    delete this.historyLastKbarTime[subscriberUID];
                this.io.emit(SocketAction.unsubscribeSymbol, { subscriberUID });
                delete this.subTopics[subscriberUID];
            },
            /**
             * @example
             *   //
             *   // 以前 chart2 createDatafeedStore 時代寫法
             *   {
             *     getServerTime: callback => {
             *       $http.get('/time').then(resp => {
             *         callback(resp.data)
             *       })
             *     }
             *   }
             */
            getServerTime: callback => {
                this.tvAPIs.fetchServerTime().then(serverTimeUnix => {
                    callback(serverTimeUnix);
                });
            },
        };
    }
    registerSocketListeners() {
        if (!this.io)
            throw new Error('並非選擇 socket 伺服器，但試圖啟用 socket 連線');
        this.io.on('connect', () => {
            debugAPI.datafeed2.log('socket.on(connect)', { subTopics: this.subTopics });
            for (const key in this.subTopics) {
                const { symbol, resolution, lastBarTime, subscriberUID } = this.subTopics[key];
                this.io?.emit(SocketAction.subscribeSymbol, {
                    symbol,
                    resolution,
                    lastBarTime,
                    subscriberUID,
                });
            }
        });
        this.io.on('disconnect', (event) => {
            debugAPI.datafeed2.log('socket.on(disconnect)', event);
        });
        this.io.on('error', (error) => {
            console.error('socket.on(error)', error);
        });
        this.io.on('data', (event) => {
            const { data, subscriberUID } = event;
            let newBars = JSON.parse(data);
            const sub = this.subTopics[subscriberUID];
            debugAPI.datafeed2.log('socket.on(data)', { newBars, subTopics: this.subTopics });
            fr_datafeedStatus.analyzeKBars(newBars);
            if (!sub)
                return;
            if (newBars.length > 0) {
                // 若尚未有lastBar時間資訊(僅在第一次收到資料觸發)
                if (!sub.lastBarTime)
                    sub.lastBarTime = this.historyLastKbarTime[subscriberUID] ?? newBars[0].time;
                // 濾掉時間比lastBar還早的K棒
                newBars = newBars.filter((b) => {
                    if (isUndefined(sub.lastBarTime))
                        return false;
                    return b['time'] >= sub.lastBarTime;
                });
                // 畫上(更新)K棒
                newBars.forEach((bar, index) => {
                    sub.lastBarTime = bar.time;
                    sub.listener(bar);
                });
            }
        });
        socketOnSleepRecoverReconnect(this.ioAsRef);
    }
    start() {
        debugAPI.datafeed2.log(`.start()`, { this: this });
        if (this.io) {
            this.io.connect();
        }
    }
    stop() {
        debugAPI.datafeed2.log(`.stop()`, { this: this });
        if (this.io) {
            this.io.disconnect();
        }
    }
    io;
    tvAPIs;
    historyLastKbarTime = {};
    subTopics = {};
}
